import { ArrowBackIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React from 'react';

export const BackButton = () => {
  const router = useRouter();

  return (
    <IconButton
      aria-label=""
      variant={'ghost'}
      color={'black'}
      onClick={() => router.back()}
      icon={<ArrowBackIcon fontSize={32} />}
    />
  );
};
