import {
  Input,
  InputAddonProps,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightAddon,
} from '@chakra-ui/react';

interface PercentageInputProps {
  inputGroupProps?: InputGroupProps;
  inputLeftAddonProps?: InputAddonProps;
  inputProps?: InputProps;
}

export const PercentageInput = ({
  inputGroupProps,
  inputLeftAddonProps,
  inputProps,
}: PercentageInputProps) => {
  return (
    <InputGroup {...inputGroupProps}>
      <Input {...inputProps} w={'inherit'} placeholder={'Amount'} autoFocus />
      <InputRightAddon {...inputLeftAddonProps}>%</InputRightAddon>
    </InputGroup>
  );
};
