import { Flex, Text } from '@chakra-ui/react';

type Stat = {
  label: string;
  value?: string | null;
};

const StatContainer = ({ label, value }: Stat) => {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      flex={2}
      gap="10px"
    >
      <Text variant={'body-sm'}>{label}</Text>
      <Text px={2} variant="subtitle-bold">
        {value}
      </Text>
    </Flex>
  );
};

export const StatsContainer = ({ stats }: { stats: Stat[] }) => {
  return (
    <Flex
      py={4}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={'24px'}
      flexBasis={'max-content'}
      borderTop={'1px solid'}
      borderColor={'brand.softLight'}
    >
      {stats.map((stat) => {
        const { label, value } = stat;
        return <StatContainer key={label} label={label} value={value} />;
      })}
    </Flex>
  );
};
