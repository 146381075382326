import { Badge } from '@chakra-ui/react';
import { CsiCode } from '@tb/common';

export const CsiBadge = ({ csiCode }: { csiCode: CsiCode }) => {
  return (
    <Badge
      key={csiCode.id}
      borderRadius={'md'}
      p={1}
      w={'fit-content'}
      display={'block'}
      whiteSpace={'pre'}
      textOverflow={'ellipsis'}
      overflow={'hidden'}
      title={`${csiCode.code} - ${csiCode.description}`}
      mb={2}
    >
      {csiCode.code} - {csiCode.description}
    </Badge>
  );
};
