import { Flex, FlexProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const PageHeaderContainer = ({
  children,
  ...rest
}: {
  children: ReactNode;
} & FlexProps) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      mb={4}
      {...rest}
    >
      {children}
    </Flex>
  );
};
