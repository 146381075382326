import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { BaseDeleteConfirmationProps } from './useDeleteConfirmation';

type DeleteConfirmationProps = {
  model: BaseDeleteConfirmationProps;
};

export const DeleteConfirmation = ({ model }: DeleteConfirmationProps) => {
  const toast = useToast();
  const {
    title,
    message,
    deleteText,
    cancelText,
    onDelete,
    onCancel,
    onClose,
    isOpen,
  } = model;
  const cancelClick = () => {
    toast({
      title: 'Canceled',
      status: 'info',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
    onCancel?.();
    onClose();
  };

  const deleteClick = () => {
    onDelete();
    onClose();
  };

  const cancelRef = useRef() as any;
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {message || "Are you sure? You can't undo this action afterwards."}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={cancelClick}
              data-testid="cancel-btn"
            >
              {cancelText || 'Cancel'}
            </Button>
            <Button
              colorScheme="red"
              onClick={deleteClick}
              ml={3}
              data-testid="delete-btn"
            >
              {deleteText || 'Delete'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
