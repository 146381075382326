const BOX_BASE_URL = 'https://app.box.com';

// const ItemType = z.enum(['file', 'folder']);

// type ItemType = z.infer<typeof ItemType>;

export const getFullFileRequestUrl = (fileRequestUrl?: string) => {
  return `${BOX_BASE_URL}${fileRequestUrl}`;
};

type EmbedOptions = {
  view: 'list' | 'icon';
  sortColumn: 'name' | 'date' | 'size';
  sortDirection: 'ASC' | 'DESC';
  showParentPath: boolean;
  showItemFeedActions: boolean;
};

const defaultOptions: EmbedOptions = {
  view: 'list',
  sortColumn: 'name',
  sortDirection: 'ASC',
  showParentPath: false,
  showItemFeedActions: false,
};

export function buildEmbedUrl(
  sharedLink: string,
  options: EmbedOptions = defaultOptions,
) {
  const {
    view,
    sortColumn,
    sortDirection,
    showParentPath,
    showItemFeedActions,
  } = options;

  let url = `${BOX_BASE_URL}/embed/s/${sharedLink}`;
  url += `?view=${view}`;
  url += `&sortColumn=${sortColumn}`;
  url += `&sortDirection=${sortDirection}`;
  url += `&showParentPath=${showParentPath}`;
  url += `&showItemFeedActions=${showItemFeedActions}`;

  return url;
}
