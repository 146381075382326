import {
  Currency,
  DollarAmount,
  OptionalDollarAmount,
  VerifiedUser,
} from '@tb/common';
import { RefObject } from 'react';
import { DeepRequired, FieldErrorsImpl } from 'react-hook-form';
import { BidActionMember } from '~/domain/aggregates/BidActionEvent';
import { CostModifier, Estimate } from '~/domain/aggregates/Estimate';
import { CostModifierAttributes } from '~/domain/repos/estimateEventHelper';

export const getCurrencyFormatFromContext = (
  value: OptionalDollarAmount['amount'],
  currency: Currency,
) => {
  return new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  }).format(Number((value || 0).toFixed(2)));
};

export const getCurrencyFormat = (
  value: number,
  currency: Currency,
  language: string,
) => {
  if (typeof value !== 'number') {
    value = Number(value);
  }
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
  }).format(Number(value.toFixed(2)));
};

export const getDateFormat = (d: Date) => {
  return d.toLocaleDateString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export const getHourFormat = (d: Date) => {
  return d.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatDollarAmount = (
  dollar: DollarAmount = { amount: 0, currency: 'USD' },
  language = 'en-US',
) => {
  const { amount, currency } = dollar;

  return getCurrencyFormat(amount, currency, language);
};

export const isValidDate = (d?: Date) => {
  return d instanceof Date && !isNaN(d as any);
};

export const getMaxDate = (dates: Date[]) =>
  new Date(Math.max(...dates.map(Number)));

export const getPathFromUrl = (url: string) => {
  // let path = '/'
  const pathRegex = /http[s]?:\/\/?[^/\s]+\/(.*)/;
  const result = url.match(pathRegex);

  if (result && result.length > 1) {
    return `/${result[1]}`;
  }
  return '/';
};

export const getMemberFromContext = (user: VerifiedUser): BidActionMember => {
  return {
    id: user.membershipId,
    name: user.name || user.email || '',
  };
};

export const getDifferenceFromArrayOfObjects = <T, K extends keyof T>(
  array1: T[],
  array2: T[],
  comparator: K | ((item: T) => string),
) => {
  if (typeof comparator === 'function') {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return comparator(object1) === comparator(object2);
      });
    });
  }
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1[comparator] === object2[comparator];
    });
  });
};

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function getBaseUrl() {
  if (typeof window !== 'undefined') {
    return '';
  }
  // reference for vercel.com
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

// Determine the active URL based on the environment.
export function getInngestUrl() {
  // Use base URL if deployed to remote server.
  if (
    process.env.NEXT_PUBLIC_BASE_URL &&
    process.env.NEXT_PUBLIC_BASE_URL.indexOf('localhost') == -1
  ) {
    return `${process.env.NEXT_PUBLIC_BASE_URL}`;
  }

  // Use Vercel URL if running on preview environment.
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  // Use host machine's IP address if running in Docker container.
  if (process.env.DOCKER_ENVIRONMENT) {
    return `http://host.docker.internal:${process.env.PORT ?? 3000}`;
  }

  // Assume running on localhost if none of the above conditions are met.
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

export const filterByUniqueProperty = <T, K>(
  arr: T[],
  grouper: (item: T) => K,
) => {
  const map = new Map<K, T>();
  arr.forEach((item) => {
    const key = grouper(item);

    map.set(key, item);
  });

  return Array.from(map.values());
};

export const isInvalid = <T, K extends keyof T>(
  errors: FieldErrorsImpl<DeepRequired<T>>,
  field: K,
) => {
  if (field in errors) {
    return true;
  } else {
    return false;
  }
};

/**
 * If the value is not null or undefined, return true, otherwise return false.
 * Useful for filtering nullish values from arrays
 * @param {TValue | undefined | null} value - TValue | undefined | null
 * @returns A function that takes a value of type TValue | undefined | null and returns a value of type
 * TValue.
 */
export function isNonNullable<TValue>(
  value: TValue | undefined | null,
): value is TValue {
  return value !== null && value !== undefined; // Can also be `!!value`.
}

export const setToArray = <T>(
  data: Set<T | undefined>,
  removeEmpty = true,
): T[] => {
  const res = Array.from(data).filter(isNonNullable);

  if (removeEmpty) {
    return res.filter((r) => r);
  }
  return res;
};

/**
 * Returns boolean indicating whether container contains overflowing text
 *
 * @param textRef - The ref input
 * @returns boolean indicating whether container contains overflowing text
 *
 */
export const checkOverflow = (textRef: RefObject<HTMLButtonElement>) => {
  if (
    textRef.current &&
    textRef.current.scrollWidth > textRef.current.clientWidth
  ) {
    return true;
  }
};

// Returns the raw numeric value of the cost modifier based on its type (percentage or lump)
export const getCostModifierValue = (
  costModifier: CostModifier | undefined,
) => {
  if (!costModifier) {
    return 0;
  }

  switch (costModifier.type) {
    case 'Lump': {
      const { amount } = costModifier.modifier as DollarAmount;
      return amount;
    }
    case 'Percentage':
      return costModifier.modifier as number;
    default:
      return 0;
  }
};

export const getCostModifierAmounts = (
  estimate: Estimate,
): Record<CostModifierAttributes, string> => {
  const { margin, markup, contingency } = estimate;

  const marginAmount = getCostModifierValue(margin);
  const markupAmount = getCostModifierValue(markup);
  const contingencyAmount = getCostModifierValue(contingency);

  const modifierAmounts: Record<CostModifierAttributes, string> = {
    contingency:
      contingency?.type === 'Lump'
        ? getCurrencyFormat(contingencyAmount, 'USD', 'en-US')
        : contingencyAmount > 0
          ? `${contingencyAmount}%`
          : '0',
    margin:
      margin?.type === 'Lump'
        ? getCurrencyFormat(marginAmount, 'USD', 'en-US')
        : marginAmount > 0
          ? `${marginAmount}%`
          : '0',
    markup:
      markup?.type === 'Lump'
        ? getCurrencyFormat(markupAmount, 'USD', 'en-US')
        : markupAmount > 0
          ? `${markupAmount}%`
          : '0',
  };

  return modifierAmounts;
};

export const getS3ImageVariant = (
  url: string,
  variant = 'large',
  initial = 'base',
) => {
  const urlParts = url.split('/');
  const fileName = urlParts[urlParts.length - 1]!;
  const newFile = fileName.replace(`-${initial}`, `-${variant}`);
  // console.log(fileName, newFile);
  return `${urlParts.slice(0, urlParts.length - 1).join('/')}/${newFile}`;
};

export const clearCsiCode = (str: string): string => {
  // Remove spaces and non-numeric characters
  let cleanedStr = str.replace(/\s/g, '').replace(/\D/g, '');

  // If length is less than or equal to 6, pad with trailing zeroes
  if (cleanedStr.length <= 6) {
    return cleanedStr.padEnd(6, '0');
  }

  // If length is more than 6, pad with trailing zeroes until length is 10
  return cleanedStr.padEnd(8, '0').substring(0, 10);
};
