import {
  CreateToastFnReturn,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react';
import snakeCase from 'lodash/snakeCase';

type ToastReturn = Pick<
  CreateToastFnReturn,
  'update' | 'promise' | 'closeAll' | 'close' | 'isActive'
>;
interface UseToastReturn extends ToastReturn {
  error: (title: string, description?: string, duration?: number) => void;
  success: (title: string, description?: string, duration?: number) => void;
  info: (title: string, description?: string, duration?: number) => void;
}

/**
 * It's a wrapper around the `useToast` hook from `@chakra-ui/core` that allows you to show a toast
 * message with a title and a description disables duplicate entries by default
 * @param options - UseToastOptions & { allowDuplicates }
 * @returns An object with two properties: toast and showToast.
 */
export const useToastHelper = (
  options: UseToastOptions & {
    allowDuplicates?: boolean;
  } = {},
): UseToastReturn => {
  const { allowDuplicates, ...opts } = options;
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top',
    ...opts,
  });

  const showToast = (
    title: string,
    status: UseToastOptions['status'],
    description?: string,
    duration = 5000,
  ) => {
    const id = snakeCase(title);

    if (allowDuplicates) {
      toast({
        title,
        status,
        description,
        duration,
      });
    } else {
      if (!toast.isActive(id)) {
        toast({
          id,
          title,
          status,
          description,
          duration,
        });
      }
    }
  };

  return {
    ...toast,
    error: (title: string, description?: string, duration?: number) => {
      showToast(title, 'error', description, duration);
    },
    success: (title: string, description?: string, duration?: number) => {
      showToast(title, 'success', description, duration);
    },
    info: (title: string, description?: string, duration?: number) => {
      showToast(title, 'info', description, duration);
    },
  };
};
