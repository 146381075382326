import { Button } from '@chakra-ui/react';
import { createRef } from 'react';
import { useIsInViewport } from '~/hooks';

export interface LoadMoreProps {
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  disableAutoLoad?: boolean;
}

export const LoadMoreButton = ({
  hasNextPage,
  fetchNextPage,
  disableAutoLoad = false,
}: LoadMoreProps) => {
  const buttonRef = createRef<HTMLButtonElement>();
  useIsInViewport(buttonRef, () => {
    if (!disableAutoLoad) {
      fetchNextPage?.();
    }
  });
  if (hasNextPage) {
    return (
      <Button
        mt={4}
        variant="link"
        ref={buttonRef}
        onClick={() => {
          fetchNextPage?.();
        }}
      >
        Load More...
      </Button>
    );
  }
  return null;
};
