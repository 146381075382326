import { buildEmbedUrl } from '~/utils/box';

export const BoxFileView = ({
  sharedLink,
  width,
  height,
}: {
  sharedLink: string;
  width: number;
  height: number;
}) => {
  return (
    <iframe
      src={buildEmbedUrl(sharedLink)}
      width={`${width}`}
      height={`${height}`}
      frameBorder="0"
      allowFullScreen
    />
  );
};
