import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  Icon,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useColorMode,
} from '@chakra-ui/react';
import {
  Breadcrumb as BreadcrumbType,
  LinkDataProps,
  SubNavLayoutConfig,
} from '@tb/common';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { MdMoreVert } from 'react-icons/md';

const SubLinkComponent = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props: LinkProps, ref) => {
    const router = useRouter();
    const active = router.asPath === props.href;

    return <Link variant={active ? 'nav_active' : ''} ref={ref} {...props} />;
  },
);

SubLinkComponent.displayName = 'SubLinkComponent';

const SubNavLink = ({
  label,
  path,
  breadCrumb,
  noOverflow,
}: LinkDataProps & {
  breadCrumb?: boolean;
}) => {
  // const router = useRouter();

  let LinkComponent;
  if (!breadCrumb) {
    LinkComponent = SubLinkComponent;
  } else {
    LinkComponent = BreadcrumbLink;
  }
  if (path) {
    return (
      <NextLink data-testid={label} href={path} passHref legacyBehavior>
        <LinkComponent
          style={
            noOverflow
              ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : undefined
          }
        >
          {label}
        </LinkComponent>
      </NextLink>
    );
  }

  return <LinkComponent>{label}</LinkComponent>;
};

export const SubNavLayout = ({
  breadCrumbs,
  breadCrumbPlaceholder,
  linkPlaceholder,
  links,
  contextMenu,
}: SubNavLayoutConfig) => {
  const [crumbs, setCrumbs] = useState<BreadcrumbType[]>(
    breadCrumbPlaceholder || [],
  );
  const [renderedLinks, setRenderedLinks] = useState<LinkDataProps[]>(
    linkPlaceholder || [],
  );

  const { colorMode } = useColorMode();

  useEffect(() => {
    const fetchCrumbs = async () => {
      const result = await breadCrumbs();
      setCrumbs(result);
    };

    fetchCrumbs();
  }, [breadCrumbs]);

  useEffect(() => {
    const fetchLinks = async () => {
      const result = await links();
      setRenderedLinks(result);
    };

    fetchLinks();
  }, [links]);

  return (
    <Flex
      borderStyle="solid"
      borderColor="brand.hold"
      w={'100%'}
      shadow={'base'}
      justifyContent={'center'}
    >
      <Flex
        bg={colorMode == 'light' ? 'brand.white' : 'gray.900'}
        alignItems="center"
        gap={1}
        py={1}
        overflowX="auto"
        h="40px"
        px="60px"
        w="100%"
      >
        {!!contextMenu && (
          <Flex w={142} h={'40px'} justify="space-evenly" align="center">
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<Icon fontSize="lg" as={MdMoreVert} />}
                variant="ghost"
                color={colorMode === 'light' ? 'complete.500' : 'complete.100'}
              >
                Actions
              </MenuButton>
              <MenuList>
                {contextMenu.map((item, idx) => {
                  return (
                    <MenuItem key={idx} onClick={item.onClick}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
            <Divider orientation="vertical" />
          </Flex>
        )}

        {crumbs.length > 0 && (
          <>
            <Breadcrumb
              variant="sub-nav"
              spacing={1}
              separator={<ChevronRightIcon fontSize={'2xl'} color="gray.500" />}
            >
              {crumbs.map((breadcrumb, idx) => {
                const { label } = breadcrumb;
                if ('options' in breadcrumb) {
                  const { options, noOverflow } = breadcrumb;
                  return (
                    <BreadcrumbItem
                      key={`${label}-${idx}`}
                      color="brand.darkGray"
                    >
                      <Menu>
                        <MenuButton
                          as={BreadcrumbLink}
                          style={
                            !noOverflow
                              ? {
                                  minWidth: '100px',
                                  maxWidth: '150px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }
                              : { whiteSpace: 'nowrap' }
                          }
                        >
                          {label}
                        </MenuButton>
                        <Portal>
                          <MenuList zIndex={3}>
                            {options.map(({ label, path }, idx) => {
                              if (path) {
                                return (
                                  <NextLink
                                    key={`${label}-${idx}`}
                                    href={path}
                                    passHref
                                    legacyBehavior
                                  >
                                    <MenuItem as={'a'}>{label}</MenuItem>
                                  </NextLink>
                                );
                              }
                              return (
                                <MenuItem key={`${label}-${idx}`}>
                                  {label}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </Portal>
                      </Menu>
                    </BreadcrumbItem>
                  );
                }
                const { path } = breadcrumb;
                return (
                  <BreadcrumbItem key={`${label}-${idx}`}>
                    <SubNavLink path={path} label={label} breadCrumb />
                  </BreadcrumbItem>
                );
              })}
            </Breadcrumb>
            <ChevronRightIcon fontSize={'2xl'} color="gray.500" mx={1} />
          </>
        )}

        <Flex alignItems={'center'} gap={8} fontSize="sm">
          {renderedLinks.map((breadcrumb, idx) => {
            const { label, path, noOverflow } = breadcrumb;
            return (
              <SubNavLink
                key={`${label}-${idx}`}
                path={path}
                label={label}
                noOverflow={noOverflow}
              />
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
};
