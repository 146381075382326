import { useEffect } from 'react';
import { createKeybindingsHandler, KeyBindingMap } from 'tinykeys';

export const useKeyPress = (map: KeyBindingMap) => {
  useEffect(() => {
    const listener = createKeybindingsHandler(map);
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [map]);
};
