import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Flex,
  FlexProps,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Card } from '@tb/ui';
import { PaginationModel } from '~/hooks';

const PAGE_SIZES = [10, 25, 50, 100, 250];
export const Pagination = ({
  model,
  borderRadius = '0px',
  ...flexProps
}: {
  model: PaginationModel;
  borderRadius?: string | number;
} & FlexProps) => {
  const { state, events, totalPages } = model;

  return (
    <Card
      justifyContent="space-evenly"
      alignItems="center"
      position={'absolute'}
      bottom={'60px'}
      zIndex={1}
      flexDir="row"
      h={'56px'}
      borderRadius={borderRadius}
      {...flexProps}
    >
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            onClick={() => events.gotoPage(0)}
            isDisabled={!state.canPreviousPage}
            icon={<ArrowLeftIcon h={3} w={3} />}
            mr={4}
            aria-label={''}
          />
        </Tooltip>
        <Tooltip label="Previous Page">
          <IconButton
            onClick={events.previousPage}
            isDisabled={!state.canPreviousPage}
            icon={<ChevronLeftIcon h={6} w={6} />}
            aria-label={''}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center">
        <Text flexShrink="0" mr={8}>
          Page{' '}
          <Text fontWeight="bold" as="span">
            {state.page + 1}
          </Text>{' '}
          of{' '}
          <Text fontWeight="bold" as="span">
            {totalPages}
          </Text>
        </Text>
        <Text flexShrink="0">Go to page:</Text>{' '}
        <NumberInput
          ml={2}
          mr={8}
          w={28}
          min={1}
          max={totalPages}
          onChange={(value) => {
            const page = Number(value) ? Number(value) - 1 : 0;
            events.gotoPage(page);
          }}
          value={state.page + 1}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Select
          w={32}
          value={state.pageSize}
          onChange={(e) => {
            events.setPageSize(Number(e.target.value));
          }}
        >
          {PAGE_SIZES.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            onClick={events.nextPage}
            isDisabled={!state.canNextPage}
            icon={<ChevronRightIcon h={6} w={6} />}
            aria-label={''}
          />
        </Tooltip>
        <Tooltip label="Last Page">
          <IconButton
            onClick={() => events.gotoPage(totalPages - 1)}
            isDisabled={!state.canNextPage}
            icon={<ArrowRightIcon h={3} w={3} />}
            ml={4}
            aria-label={''}
          />
        </Tooltip>
      </Flex>
    </Card>
  );
};
