import { Button, Flex, FlexProps } from '@chakra-ui/react';
import { CsiCode, Trade } from '@tb/common';
import { TradeRenderer } from '@tb/ui';
import { useState } from 'react';
import { CsiCodeRenderer } from '~/components/common/CsiCodeRenderer';

const DEFAULT_TRADE_LIMIT = 4;
const DEFAULT_TRADE_OFFSET = 5;

export const BidTradesList = ({
  trades,
  ...flexProps
}: { trades: Trade[] } & FlexProps) => {
  const [tradeMax, setTradeMax] = useState(DEFAULT_TRADE_LIMIT);
  const showMore = () => {
    setTradeMax((v) => v + DEFAULT_TRADE_OFFSET);
  };
  const collapseTrades = () => {
    setTradeMax(DEFAULT_TRADE_LIMIT);
  };

  return (
    <Flex gap={2} flexWrap="wrap" justifyContent="end" {...flexProps}>
      {trades.map((trade, idx) => {
        if (idx < tradeMax) {
          return (
            <TradeRenderer key={trade.id} trade={trade} isButton={false} />
          );
        }
        return null;
      })}
      {DEFAULT_TRADE_LIMIT < trades.length &&
        (tradeMax < trades.length ? (
          <Button variant={'link'} size="xs" onClick={showMore}>
            Show More...
          </Button>
        ) : (
          <Button variant="link" size="xs" onClick={collapseTrades}>
            Collapse
          </Button>
        ))}
    </Flex>
  );
};

export const BidCsiCodeList = ({
  csiCodes,
  ...flexProps
}: { csiCodes: CsiCode[] } & FlexProps) => {
  const [csiCodeMax, setCsiCodeMax] = useState(DEFAULT_TRADE_LIMIT);
  const showMore = () => {
    setCsiCodeMax((v) => v + DEFAULT_TRADE_OFFSET);
  };
  const collapseTrades = () => {
    setCsiCodeMax(DEFAULT_TRADE_LIMIT);
  };

  return (
    <Flex gap={2} flexWrap="wrap" justifyContent="end" {...flexProps}>
      {csiCodes.map((csiCode, idx) => {
        if (idx < csiCodeMax) {
          return <CsiCodeRenderer key={csiCode.id} csiCode={csiCode} />;
        }
        return null;
      })}
      {DEFAULT_TRADE_LIMIT < csiCodes.length &&
        (csiCodeMax < csiCodes.length ? (
          <Button variant={'link'} size="xs" onClick={showMore}>
            Show More...
          </Button>
        ) : (
          <Button variant="link" size="xs" onClick={collapseTrades}>
            Collapse
          </Button>
        ))}
    </Flex>
  );
};
