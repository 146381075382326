import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonProps,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useColorModeStuff } from '../ColorMode';

type OptionValue = string | number;

type OptionLabel = string;

export type Option<T extends OptionValue> = {
  label: OptionLabel;
  value: T;
  onClick?: () => void;
  /**
   * @deprecated use menuItemProps instead.
   */
  style?: React.CSSProperties;
  /**
   * @deprecated use menuItemProps instead.
   */
  borderColor?: string;
  menuItemProps?: MenuItemProps;
};

export type DropdownModel<T extends OptionValue> = {
  placeholder?: string;
  select: (value: T) => void;
  selectedOption?: T;
  options: Option<T>[];
  itemWidth?: string;
  clear: () => void;
  doNotChangeValue?: boolean;
  leftIcon?: JSX.Element;
  itemStyle?: React.CSSProperties;
  listStyle?: React.CSSProperties;
};

export type DropdownConfig<T extends OptionValue> = {
  /**
   * Required. Placeholder text for the dropdown if nothing has been selected.
   */
  placeholder?: string;
  /**
   * Optional. If not provided, the dropdown will display the placeholder
   */
  initialValue?: T;
  /**
   * Required. The options to choose from in the dropdown.
   */
  options: Option<T>[];
  /**
   * Required. This will be invoked when an option is selected.
   */
  onSelected?: (value: T | undefined) => void;
  /**
   * @deprecated Use `listStyle` instead.
   * Optional. If not provided, the width of the dropdown will be the width of the longest option.
   */
  itemWidth?: string;
  /**
   * Optional. Apply custom styles to the dropdown list.
   */
  listStyle?: React.CSSProperties;
  /**
   * Optional. Apply custom styles to the dropdown items.
   */
  itemStyle?: React.CSSProperties;
  /**
   * Optional. If true, the dropdown will not change its value when an option is selected.
   */
  doNotChangeValue?: boolean;
  /**
   * Optional. Give the dropdown an icon to the left of the placeholder.
   */
  leftIcon?: JSX.Element;
};

export function useDropdownModel<T extends OptionValue>({
  placeholder,
  initialValue,
  options,
  onSelected,
  itemStyle,
  listStyle,
  itemWidth,
  doNotChangeValue,
  leftIcon,
}: DropdownConfig<T>): DropdownModel<T> {
  const [selectedOption, setSelectedOption] = useState(initialValue);

  function select(value: T) {
    if (!doNotChangeValue) {
      setSelectedOption(value);
    }
    onSelected?.(value);
  }

  function clear() {
    setSelectedOption(undefined);
    onSelected?.(undefined);
  }

  return {
    placeholder,
    select,
    selectedOption,
    options,
    itemWidth,
    itemStyle,
    listStyle,
    clear,
    doNotChangeValue,
    leftIcon,
  };
}

interface DropDownProps<T extends OptionValue> extends ButtonProps {
  model: DropdownModel<T>;
}

export const Dropdown = <T extends OptionValue>({
  model,
  ...rest
}: DropDownProps<T>) => {
  const {
    placeholder,
    select,
    selectedOption,
    options,
    itemWidth,
    itemStyle,
    listStyle,
    clear,
    doNotChangeValue,
    leftIcon,
  } = model;

  const selectedValue = options.find(({ value }) => value === selectedOption);
  const selectedLabel = selectedOption ? selectedValue?.label : undefined;

  const { colorModeSaturation5 } = useColorModeStuff();

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        filter={colorModeSaturation5}
        bgColor={selectedValue?.borderColor}
        {...rest}
        sx={{
          '& div': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
        data-testid="menu-btn"
        _hover={{ bgColor: selectedValue?.borderColor }}
        _active={{ bgColor: selectedValue?.borderColor }}
      >
        {leftIcon ? (
          <Flex gap={1}>
            {leftIcon}
            {selectedLabel || placeholder}
          </Flex>
        ) : (
          <>{selectedLabel || placeholder}</>
        )}
      </MenuButton>
      <MenuList minWidth={itemWidth} style={listStyle}>
        {doNotChangeValue || !placeholder ? null : (
          <MenuItem
            style={{ ...itemStyle, ...selectedValue?.style }}
            key={placeholder}
            justifyContent={'center'}
            onClick={() => clear()}
          >
            {placeholder}
          </MenuItem>
        )}
        {options.map(({ label, value, style, onClick, menuItemProps }) => (
          <MenuItem
            {...menuItemProps}
            bg={'transparent'}
            filter={colorModeSaturation5}
            style={{ ...itemStyle, ...style }}
            value={value}
            key={value}
            data-testid={`${label.toLowerCase()}-menu-item`}
            onClick={() => {
              select(value);
              onClick?.();
            }}
            justifyContent={'center'}
            padding={'8px'}
            _hover={{ display: 'none' }}
          >
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
