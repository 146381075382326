import { RefObject, useEffect, useMemo, useState } from 'react';

export function useIsInViewport(
  ref: RefObject<HTMLElement>,
  callback?: () => void,
) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        const intersectResult = entry ? entry.isIntersecting : false;
        setIsIntersecting(intersectResult);

        if (intersectResult && callback) {
          callback();
        }
      }),
    [],
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return { isIntersecting };
}
