import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

interface ControlledMonetaryInputProps<
  FormValues extends FieldValues = FieldValues,
> extends UseControllerProps<FormValues> {
  label?: string;
  currency?: 'USD';
  isRequired?: boolean;
  isDisabled?: boolean;
}

export const ControlledMonetaryInput = <
  FormValues extends FieldValues = FieldValues,
>({
  name,
  label,
  control,
  rules,
  shouldUnregister,
  currency = 'USD',
  isDisabled,
  isRequired,
}: ControlledMonetaryInputProps<FormValues>) => {
  const {
    field,
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
    shouldUnregister,
  });

  const handleChange: CurrencyInputProps['onValueChange'] = (
    _valueAsString,
    _name,
    values,
  ) => {
    field.onChange(values?.float);
  };

  const bgColor = useColorModeValue('white', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <Input
        textAlign={'right'}
        placeholder={'$'}
        as={CurrencyInput}
        decimalsLimit={2}
        disabled={isDisabled}
        id={name}
        intlConfig={{ locale: window.navigator.language, currency }}
        defaultValue={field.value}
        onValueChange={handleChange}
        backgroundColor={bgColor}
        color={textColor}
      />
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
};
