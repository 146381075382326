import { Currency } from '@tb/common';
import type { ICellRendererParams } from 'ag-grid-community';
import { EstimateLineItem } from '~/domain/aggregates/Estimate';
import { getCurrencyFormat } from '~/utils/helpers';

export const currencyValueFormatter = (params: {
  value: {
    amount: string;
    currency: Currency;
  };
}) => {
  if (!params.value) {
    return '--';
  }

  const { amount, currency } = params.value;

  return getCurrencyFormat(Number(amount), currency, 'en-US');
};

export const currencyCellRenderer = (
  params: ICellRendererParams<EstimateLineItem>,
) => {
  const { value, node: row, colDef } = params;

  if (!colDef?.enableValue && row.group) {
    return '...';
  }

  if (value === null || value === undefined) {
    return '';
  }

  if (value === 0) {
    return currencyValueFormatter({
      value: { amount: '0', currency: 'USD' },
    });
  }
  const amount = value;
  const currency = 'USD';
  return currencyValueFormatter({
    value: { amount: String(amount), currency },
  });
};
