import {
  Box,
  chakra,
  Flex,
  IconButton,
  IconButtonProps,
  Tbody,
  Td,
  Tr,
  useColorMode,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { memo, ReactNode, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import { StrictModeDroppable } from './Utils';

type Props<T> = {
  [Key in keyof T]: NonNullable<T[Key]>;
};

type ReorderReturn<T> = T & {
  orderIndex: number;
};

type KeyTypes<T> = {
  [K in keyof T]-?: K extends string
    ? string
    : K extends number
      ? number
      : K extends symbol
        ? symbol
        : never;
}[keyof T];

type KeyOfType<
  T,
  KeyType extends string | number | symbol = KeyTypes<T>,
> = Extract<keyof T, KeyType>;

type ControlledProps<T extends Record<string | number | symbol, any>> =
  | {
      controlled?: false;
      orderKey?: KeyOfType<T>;
      onReorder?: (items: ReorderReturn<T>[]) => void;
    }
  | {
      controlled: true;
      orderKey: KeyOfType<T>;
      onReorder: (items: ReorderReturn<T>[]) => void;
    };

type DragAndDropProps<T extends Record<string | number | symbol, any>> = {
  items: T[];
  idKey: KeyOfType<T>;
  containerDrag?: boolean;
  table?: boolean;
  shouldDisableDrag?: (item: T) => boolean;
  children(props: Props<T>): JSX.Element;
} & ControlledProps<T>;

export const DragAndDropList = <
  T extends Record<string | number | symbol, any>,
  //   K extends keyof T,
>(
  props: DragAndDropProps<T>,
) => {
  const {
    idKey,
    children,
    onReorder,
    table,
    controlled,
    orderKey,
    shouldDisableDrag,
    ...rest
  } = props;
  const [items, setItems] = useState(props.items);

  const reorder = (list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    if (removed) {
      result.splice(endIndex, 0, removed);
    }

    return result;
  };

  useMemo(() => {
    if (!controlled) {
      setItems(props.items);
    }
  }, [props.items, controlled]);

  const onDragEnd = (result: DropResult, _provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      controlled ? props.items : items,
      result.source.index,
      result.destination.index,
    );

    if (!controlled) {
      setItems(reorderedItems);
    }

    onReorder?.(
      reorderedItems.map((item, idx) => {
        if (controlled && orderKey) {
          return {
            ...item,
            [orderKey]: idx + 1,
            orderIndex: idx + 1,
          };
        }
        return {
          ...item,
          orderIndex: idx + 1,
        };
      }),
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppable">
        {(provided, _snapshot) => {
          if (table) {
            return (
              <Tbody ref={provided.innerRef} {...provided.droppableProps}>
                <ListContainer
                  {...rest}
                  items={controlled ? props.items : items}
                  idKey={idKey}
                  table={table}
                  shouldDisableDrag={shouldDisableDrag}
                >
                  {children}
                </ListContainer>
                {provided.placeholder}
              </Tbody>
            );
          }

          return (
            <StyledResponseList
              ref={provided.innerRef}
              {...provided.droppableProps}
              flexDir={'column'}
              gap={3}
            >
              <ListContainer
                {...rest}
                table={table}
                items={controlled ? props.items : items}
                idKey={idKey}
                shouldDisableDrag={shouldDisableDrag}
              >
                {children}
              </ListContainer>

              {provided.placeholder}
            </StyledResponseList>
          );
        }}
      </StrictModeDroppable>
    </DragDropContext>
  );
};

const ListContainer = memo(
  <T extends Record<string | number | symbol, any>>({
    items,
    idKey,
    children,
    table,
    ...rest
  }: Omit<DragAndDropProps<T>, 'onReorder'>) => {
    return (
      <>
        {items.map((item, index) => (
          <ListItem
            key={item[idKey] as any}
            idKey={idKey}
            item={item}
            index={index}
            table={table}
            {...rest}
            isDisabled={rest.shouldDisableDrag?.(item)}
          >
            {children({
              ...(item as any),
            })}
          </ListItem>
        ))}
      </>
    );
  },
);
ListContainer.displayName = 'ListContainer';

const DragButton = ({
  provided,
  ...props
}: { provided: DraggableProvided } & Omit<IconButtonProps, 'aria-label'>) => {
  const { colorMode } = useColorMode();
  return (
    <IconButton
      {...provided.dragHandleProps}
      data-testid="drag-and-drop-handle"
      mr={4}
      variant={colorMode === 'light' ? 'outline' : 'solid'}
      aria-label="drag"
      icon={<MdDragIndicator />}
      {...props}
    />
  );
};

type ListItemProps<T extends Record<string | number | symbol, any>> = Omit<
  DragAndDropProps<T>,
  'items' | 'children'
> & {
  item: T;
  index: number;
  children: ReactNode;
  isDisabled?: boolean;
};

const ListItem = <T extends Record<string | number | symbol, any>>({
  item,
  index,
  children,
  idKey,
  table,
  containerDrag,
  isDisabled,
}: ListItemProps<T>) => {
  return (
    <Draggable draggableId={String(item[idKey])} index={index}>
      {(provided, snapshot) => {
        if (table) {
          return (
            <StyledResponseTableItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...(containerDrag && provided.dragHandleProps)}
              _active={
                snapshot.isDragging
                  ? {
                      shadow: 'base',
                      padding: '4px',
                      bg: 'brand.navLight',
                    }
                  : {}
              }
            >
              <Td>
                <DragButton provided={provided} isDisabled={isDisabled} />
              </Td>
              {children}
            </StyledResponseTableItem>
          );
        }
        return (
          <StyledResponseItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...(containerDrag && provided.dragHandleProps)}
            _active={
              snapshot.isDragging
                ? {
                    shadow: 'base',
                    padding: '4px',
                    bg: 'brand.navLight',
                  }
                : {}
            }
          >
            <>
              <DragButton provided={provided} isDisabled={isDisabled} />
              <Box w="100%">{children}</Box>
            </>
          </StyledResponseItem>
        );
      }}
    </Draggable>
  );
};

const StyledResponseList = styled(Flex)`
  padding: 8px;
  margin-top: 0;
  margin-bottom: 16px;
  margin-inline: 8px;

  width: inherit;
`;

const StyledResponseTableItem = chakra(Tr, {
  baseStyle: {
    borderRadius: '8px',
  },
});

const StyledResponseItem = chakra(Box, {
  baseStyle: {
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});
