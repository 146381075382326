import { HStack, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { useFeatureFlags } from '~/utils/features/useFeatureFlags';

export const DataGridExportsToolbar = ({
  handleExportAsCsv,
  handleExportAsExcel,
  handleExportAsPdf,
}: {
  handleExportAsExcel?: () => void;
  handleExportAsCsv?: () => void;
  handleExportAsPdf?: () => void;
}) => {
  const { isEnabled } = useFeatureFlags();

  return (
    <HStack>
      {!!handleExportAsExcel && (
        <Tooltip label="Export as Excel">
          <IconButton
            icon={<Icon as={FaFileExcel} fontSize={20} />}
            aria-label=""
            size={'sm'}
            onClick={handleExportAsExcel}
          />
        </Tooltip>
      )}

      {!!handleExportAsCsv && (
        <Tooltip label="Export as CSV">
          <IconButton
            icon={<Icon as={FaFileCsv} fontSize={20} />}
            aria-label=""
            size={'sm'}
            onClick={handleExportAsCsv}
          />
        </Tooltip>
      )}

      {!!handleExportAsPdf && !!isEnabled('marek-reporting') && (
        <Tooltip label="Export as PDF">
          <IconButton
            icon={<Icon as={FaFilePdf} fontSize={20} />}
            aria-label=""
            size={'sm'}
            onClick={handleExportAsPdf}
          />
        </Tooltip>
      )}
    </HStack>
  );
};
