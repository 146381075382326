import { Button, ButtonProps } from '@chakra-ui/react';
import { GoPlus } from 'react-icons/go';

export const CreateButton = ({ children, variant, ...rest }: ButtonProps) => {
  return (
    <Button variant={variant || 'primary'} leftIcon={<GoPlus />} {...rest}>
      {children}
    </Button>
  );
};
