import { HamburgerIcon } from '@chakra-ui/icons';
import { Flex, IconButton, IconButtonProps, Spacer } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdMenuOpen } from 'react-icons/md';

const OPEN_WIDTH = '216px';
const CLOSED_WIDTH = '74px';

type SidePanelProps = {
  model: SidePanelModel;
  children: React.ReactNode;
};

export const SidePanel = ({ children, model }: SidePanelProps) => {
  const { isExpanded, header, toggle } = model;

  return (
    <Flex
      py="12px"
      direction="column"
      alignItems="center"
      transition={'width 300ms ease'}
      width={isExpanded ? OPEN_WIDTH : CLOSED_WIDTH}
    >
      <ToggleButton
        isExpanded={isExpanded}
        onClick={toggle}
        aria-label={'toggle'}
      />
      <ChildrenContainer isExpanded={isExpanded}>
        {isExpanded ? <>{header}</> : <Spacer />}
        {children}
      </ChildrenContainer>
    </Flex>
  );
};

const ToggleButton = ({
  isExpanded,
  ...rest
}: IconButtonProps & Pick<SidePanelModel, 'isExpanded'>) => (
  <IconButton
    zIndex={1}
    marginRight={isExpanded ? '12px' : '0'}
    marginBottom={isExpanded ? '12px' : '0'}
    alignSelf={isExpanded ? 'flex-end' : 'center'}
    top="68px"
    color="brand.darkGray"
    data-testid="toggleButton"
    variant="ghost"
    icon={
      isExpanded ? (
        <MdMenuOpen fontSize={24} />
      ) : (
        <HamburgerIcon fontSize={24} />
      )
    }
    _hover={{ backgroundColor: 'brand.navActive' }}
    _active={{ backgroundColor: 'brand.navActive' }}
    {...rest}
  />
);

const ChildrenContainer = ({
  children,
  isExpanded,
}: {
  children: React.ReactNode;
  isExpanded: boolean;
}) => (
  <Flex
    direction={'column'}
    alignItems={'flex-start'}
    height={'100%'}
    paddingTop={'15px'}
    paddingBottom={'30px'}
    gap={'48px'}
    width={isExpanded ? 'inherit' : '64px'}
  >
    {children}
  </Flex>
);

export type SidePanelModel = {
  isExpanded: boolean;
  toggle: () => void;
  collapse: () => void;
  expand: () => void;
  header?: string | React.ReactNode;
};

type SidePanelOptions = {
  isExpanded?: boolean;
  onCollapse?: () => void;
  onExpand?: () => void;
  header?: string | React.ReactNode;
};

export const useSidePanel = ({
  isExpanded,
  onCollapse,
  onExpand,
  header,
}: SidePanelOptions) => {
  const [expanded, setExpanded] = useState(isExpanded || false);

  const expand = () => {
    onExpand && onExpand();
    setExpanded(true);
  };
  const collapse = () => {
    onCollapse && onCollapse();
    setExpanded(false);
  };
  const toggle = () => (expanded ? collapse() : expand());

  return {
    isExpanded: expanded,
    toggle,
    collapse,
    expand,
    header,
  };
};
