import {
  Flex,
  Input,
  InputGroup,
  InputProps,
  InputRightAddon,
  Radio,
  RadioGroup,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { useDebouncedState } from '@tb/common';
import { forwardRef, useState } from 'react';
import { CurrencyInputProps } from 'react-currency-input-field';
import ReactDatePicker from 'react-datepicker';
import { MdCalendarToday } from 'react-icons/md';
import { MonetaryInput } from '~/components/common/MonetaryInput';
import { PercentageInput } from '~/components/common/PercentageInput/PercentageInput';
import { QualificationAnswerUnion } from '~/domain/aggregates/QualificationForm';
import { DatePickerWrapper } from '../ControlledDatePicker/DatePickerWrapper';

interface DynamicInputRendererProps
  extends Omit<InputProps, 'type' | 'onChange'> {
  // data:
  onChange: (d: QualificationAnswerUnion) => void;
  answer: QualificationAnswerUnion;
}

const customDateInput = ({ value, onClick, onChange }: any, ref: any) => (
  <Input
    autoComplete="off"
    background="white"
    value={value}
    ref={ref}
    onClick={onClick}
    onChange={onChange}
    placeholder="mm/dd/yyyy"
    borderRight={0}
    borderTopRightRadius={0}
    borderBottomRightRadius={0}
  />
);
customDateInput.displayName = 'DateInput';

const CustomInput = forwardRef(customDateInput);

export const DynamicInputRenderer = ({
  answer,
  onChange,
}: DynamicInputRendererProps) => {
  const dateFormat = 'MM/dd/yyyy';

  const defaultStringValue = () => {
    if (
      answer.responseType === 'TEXT' ||
      answer.responseType === 'PERCENTAGE'
    ) {
      return answer.value ?? '';
    } else {
      return '';
    }
  };
  const { debounceValue, setDebounceValue } = useDebouncedState<string>(
    defaultStringValue(),
    500,
    (res) => {
      if (
        answer.responseType === 'TEXT' ||
        answer.responseType === 'PERCENTAGE'
      ) {
        onChange({
          responseType: answer.responseType,
          value: res,
        });
      }
    },
  );

  const defaultNumberValue = () => {
    if (answer.responseType === 'MONETARY') {
      return answer.value?.amount || 0;
    } else {
      return 0;
    }
  };
  const { debounceValue: monetaryValue, setDebounceValue: setMonetaryValue } =
    useDebouncedState<number>(defaultNumberValue(), 500, (res) => {
      if (answer.responseType === 'MONETARY') {
        onChange({
          responseType: answer.responseType,
          value: {
            amount: Number(res),
            currency: 'USD',
          },
        });
      }
    });

  const [isActive, setIsActive] = useState(false);

  const iconBg = useColorModeValue('#037632', 'gray.700');
  const iconColor = useColorModeValue('#fff', '#12B856');

  switch (answer.responseType) {
    case 'DATE':
      return (
        <DatePickerWrapper>
          <InputGroup
            zIndex={isActive ? 2 : undefined}
            onFocus={() => setIsActive(true)}
          >
            <ReactDatePicker
              onCalendarClose={() => setIsActive(false)}
              selected={answer.value}
              onChange={(d) => {
                if (d) {
                  onChange({
                    responseType: answer.responseType,
                    value: d,
                  });
                }
              }}
              isClearable
              className="react-datapicker__input-text"
              dateFormat={dateFormat}
              customInput={<CustomInput />}
            />
            <InputRightAddon bg={iconBg}>
              <MdCalendarToday color={iconColor} />
            </InputRightAddon>
          </InputGroup>
        </DatePickerWrapper>
      );

    case 'TEXT':
      return (
        <Textarea
          value={debounceValue}
          onChange={(e) => {
            setDebounceValue(e.target.value);
          }}
        />
      );

    case 'BOOLEAN':
      return (
        <RadioGroup
          onChange={(nextVal) => {
            const val = nextVal === '1' ? true : false;
            onChange({
              responseType: answer.responseType,
              value: val,
            });
          }}
          value={answer.value ? '1' : '0'}
        >
          <Flex flexDir={'row'} gap={2}>
            <Radio value="1">Yes</Radio>
            <Radio value="0">No</Radio>
          </Flex>
        </RadioGroup>
      );
    case 'PERCENTAGE':
      return (
        <PercentageInput
          inputProps={{
            value: debounceValue,
            onChange: (e) => {
              setDebounceValue(e.target.value);
            },
          }}
        />
      );
    case 'MONETARY': {
      const monetaryChange: CurrencyInputProps['onValueChange'] = (
        _valueAsString,
        _name,
        values,
      ) => {
        setMonetaryValue(values?.float || 0);
      };

      return (
        <MonetaryInput
          value={{
            amount: monetaryValue,
            currency: 'USD',
          }}
          onValueChange={monetaryChange}
        />
      );
    }
  }
};
