import {
  Flex,
  FlexProps,
  Icon,
  IconButton,
  IconButtonProps,
} from '@chakra-ui/react';
import { useState } from 'react';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';

export const FullScreenView = ({
  children,
  fullScreen,
  ...rest
}: {
  children: React.ReactNode;
  fullScreen: boolean;
} & FlexProps) => {
  return (
    <Flex
      w={fullScreen ? '-webkit-fill-available' : 'inherit'}
      transition={'all 200ms ease-out'}
      overflow={'hidden'}
      flexDir={'column'}
      justifyContent={'flex-start'}
      position={fullScreen ? 'absolute' : 'inherit'}
      top={fullScreen ? 16 : 'inherit'}
      zIndex={fullScreen ? 1 : 'inherit'}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export const FullScreenButton = ({
  fullScreen,
  ...rest
}: {
  fullScreen: boolean;
} & Omit<IconButtonProps, 'aria-label'>) => (
  <IconButton
    data-testid="toggleFullscreen"
    alignSelf={'flex-end'}
    justifyContent={'flex-end'}
    pointerEvents={'all'}
    aria-label="fullscreen button"
    variant={'primary'}
    color={'white'}
    borderRadius={'full'}
    padding={2}
    height={'auto'}
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
    }}
    icon={
      <Icon
        as={fullScreen ? MdFullscreenExit : MdFullscreen}
        data-testid={fullScreen ? 'minimizeIcon' : 'maximizeIcon'}
        // style={{ transform: fullScreen ? 'rotate(180deg)' : '' }}
        fontSize={'32px'}
      />
    }
    {...rest}
  />
);

export const useFullScreen = (config?: { initial?: boolean }) => {
  const { initial = false } = config || {};
  const [fullScreen, setFullScreen] = useState(initial);
  const onToggle = () => setFullScreen((prev) => !prev);

  return { fullScreen, onToggle };
};

export const FloatingFullScreenPanel = ({
  fullScreen,
  onToggle,
}: {
  fullScreen: boolean;
  onToggle: () => void;
}) => (
  <Flex
    w={'100%'}
    justifyContent={'flex-end'}
    position={'fixed'}
    bottom={'8px'}
    right={0}
    zIndex={100}
  >
    <FullScreenButton
      fullScreen={fullScreen}
      onClick={onToggle}
      mr={12}
      colorScheme={'complete'}
      title={fullScreen ? 'Minimize view' : 'Maximize view'}
      style={{
        boxShadow: '2px 4px 4px 1px rgba(0, 0, 0, 0.25)',
      }}
    />
  </Flex>
);
