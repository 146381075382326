import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

export type PaginationConfig = {
  page?: number;
  pageSize?: number;
};

export type PaginationModel = {
  state: {
    page: number;
    pageSize: number;
    pageCount: number;
    pageOptions: number[];
    canPreviousPage: boolean;
    canNextPage: boolean;
  };
  events: {
    setPageSize: Dispatch<SetStateAction<number>>;
    nextPage: () => void;
    previousPage: () => void;
    gotoPage: (page: number) => void;
    setPageCount: Dispatch<SetStateAction<number>>;
    setCanNextPage: Dispatch<SetStateAction<boolean>>;
    setCanPreviousPage: Dispatch<SetStateAction<boolean>>;
    setCurrentPage: Dispatch<SetStateAction<number>>;
  };
  totalPages: number;
};

export const usePagination = ({
  page = 1,
  pageSize = 10,
}: PaginationConfig): PaginationModel => {
  const [currentPage, setCurrentPage] = useState(page);
  const [currentPageSize, setCurrentPageSize] = useState(pageSize);
  const [pageCount, setPageCount] = useState(0);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(true);

  const pageOptions = [0];

  const totalPages = useMemo(
    () => Math.ceil(pageCount / currentPageSize) || 1,
    [pageCount, currentPageSize],
  );

  useEffect(() => {
    if (totalPages === currentPage + 1) {
      setCanNextPage(false);
    } else {
      setCanNextPage(true);
    }

    if (currentPage === 0) {
      setCanPreviousPage(false);
    } else {
      setCanPreviousPage(true);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    setCurrentPage(0);
  }, [totalPages]);

  return {
    state: {
      page: currentPage,
      pageSize: currentPageSize,
      pageCount,
      pageOptions,
      canPreviousPage,
      canNextPage,
    },
    events: {
      setPageSize: setCurrentPageSize,
      nextPage: () => setCurrentPage((page) => page + 1),
      previousPage: () => setCurrentPage((page) => page - 1),
      gotoPage: setCurrentPage,
      setPageCount,
      setCanNextPage,
      setCanPreviousPage,
      setCurrentPage,
    },
    totalPages,
  };
};
