import { Badge, Button } from '@chakra-ui/react';
import { Trade } from '@tb/common';

export const TradeRenderer = ({
  trade,
  isButton = true,
}: {
  trade: Trade;
  isButton?: boolean;
}) => {
  if (isButton) {
    return (
      <Button size="sm" variant="outline">
        {trade.name}
      </Button>
    );
  } else {
    return (
      <Badge
        key={trade.id}
        borderRadius={2}
        p={1}
        display={'block'}
        whiteSpace={'pre'}
        maxW={['300px', '300px', '300px', '414px']}
        textOverflow={'ellipsis'}
        overflow={'hidden'}
        title={trade.name}
      >
        {trade.name}
      </Badge>
    );
  }
};
