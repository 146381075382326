import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback, useEffect } from 'react';
import { FeatureFlag } from '~/domain/Feature/aggregates';
import { CacheAtomDefault, CachedAtom } from '~/utils/atoms';
import { useUtils } from '~/utils/trpc';

// Quick test console command to remove localStorage item:
// localStorage.removeItem('featuresCache');
const featuresCacheAtom = atomWithStorage<CachedAtom<FeatureFlag[]>>(
  'featuresCache',
  CacheAtomDefault,
);

const MINUTES = 5;
// default refresh interval is 5 minutes
const defaultRefreshInterval = MINUTES * 60 * 1000;

export const useFeatureFlags = () => {
  const utils = useUtils();
  const [featuresCache, setFeaturesCache] = useAtom(featuresCacheAtom);
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  const isLoading = false; // workaround to keep from having constant page refreshes

  useEffect(() => {
    let isSubscribed = true; // Flag to prevent state update if unmounted

    const updateFeatures = async () => {
      // setIsLoading(true);
      try {
        const res = await utils.client.feature.getFeatures.query(undefined);
        if (isSubscribed) {
          setFeaturesCache({
            data: res,
            expires: new Date(Date.now() + defaultRefreshInterval),
          });
        }
      } finally {
        // setIsLoading(false);
      }
    };
    updateFeatures();

    // Setting up the interval
    const intervalId = setInterval(updateFeatures, defaultRefreshInterval);

    // Cleanup function to clear the interval
    return () => {
      clearInterval(intervalId);
      isSubscribed = false;
    };
  }, []);

  const isEnabled = useCallback(
    (featureName: string) => {
      // if no features are loaded or found, return default
      if (featuresCache === undefined) {
        return false;
      }
      const feature = featuresCache.data?.find((f) => f.name === featureName);

      if (feature === undefined) {
        return false;
      }

      return feature.isEnabled;
    },
    [featuresCache],
  );

  const restrictedPaths = (featureName: string): string[] => {
    // if no features are loaded or found, return default
    if (featuresCache === undefined) {
      return [];
    }

    return (
      featuresCache.data?.find((f) => f.name === featureName)?.allowedPaths ??
      []
    );
  };

  return {
    isEnabled,
    restrictedPaths,
    isLoading,
  };
};
