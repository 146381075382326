import { FormControl } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const DatePickerWrapper: any = styled(FormControl)`
  .react-datepicker {
    display: flex;
    font-family: unset;
    border-radius: 4px;
    background-color: var(--datepicker-bg);
    box-shadow: var(--chakra-shadows-md);
    border-color: var(--chakra-colors-brand-mediumGray);
    /* color: var(--chakra-colors-chakra-body-text); */
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: var(--chakra-colors-chakra-body-text);
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: var(--chakra-colors-chakra-body-text);
  }
  .react-datepicker__navigation {
    color: var(--chakra-colors-chakra-body-text);
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 4px;
  }
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background-color: var(--chakra-modal-bg);
    border-bottom: 0px;
  }
  .react-datepicker__day-names {
    margin-top: 8px;
    font-weight: bold;
  }

  .react-datepicker__navigation {
    top: 28px;
  }

  .react-datepicker__close-icon::after {
    background-color: transparent !important;
    color: var(--datepicker-close-icon-color);
    border-radius: unset;
    font-size: 1.5rem;
    font-weight: normal;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    background: var(--datepicker-date-bg-selected);
    font-weight: bold;
    border-radius: 50%;
    color: #fff;
  }

  .react-datepicker__month-container {
    padding: 16px;
  }
  .react-datepicker__time-container {
    padding-top: 16px;
    padding-bottom: 8px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: var(--datepicker-time-bg-selected);
    font-weight: bold;
    border-radius: 8px;
    margin-right: 4px;
    margin-left: 4px;
    color: #fff;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: var(--datepicker-time-bg-selected);
    font-weight: bold;
    color: var(--chakra-colors-chakra-body-text);
    border-radius: 50%;
    color: #fff;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 50%;
    background-color: var(--chakra-colors-chakra-placeholder-color);
    color: #fff;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background: var(--chakra-colors-chakra-placeholder-color);
    color: #fff;
  }

  .react-datepicker__time-container .react-datepicker__time {
    background-color: var(--chakra-modal-bg);
    color: var(--chakra-colors-chakra-body-text);
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: var(--chakra-colors-chakra-body-text);
    border-width: 2px 2px 0 0;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: normal;
    font-size: var(--chakra-fontSizes-sm);
    margin-bottom: 32px;
  }

  .react-datepicker__day--outside-month {
    color: var(--chakra-colors-chakra-subtle-text);
  }
`;
