import { AuthStatus, UserType } from '@tb/common';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { Role } from '~/domain/aggregates/Membership';

function isValid(
  session: Session | null,
  roles: Role[],
  allowedUserType: UserType,
  allowSelf: boolean,
): boolean {
  if (!session || !session.user) return false;

  const { type, role } = session.user;

  if (allowSelf) return true;

  return type === allowedUserType && roles.includes(role);
}

export type RoleValidatorModel = {
  status: AuthStatus;
  view: React.ReactNode;
  valid: boolean;
  session: Session | null;
};

type RoleValidatorOpts = {
  allowSelf?: boolean;
  enabledView?: React.ReactNode;
  disabledView?: React.ReactNode;
};

export const useRoleValidator = (
  allowedRoles: Role[],
  allowedUserType: UserType,
  opts?: RoleValidatorOpts,
) => {
  const { data: session, status } = useSession();

  const shouldAllowSelf = opts?.allowSelf || false;

  const valid =
    status === 'authenticated' &&
    isValid(session, allowedRoles, allowedUserType, shouldAllowSelf);

  let view = null;

  if (valid && opts && opts.enabledView) {
    view = opts.enabledView;
  }

  if (!valid && opts && opts.disabledView) {
    view = opts.disabledView;
  }

  const model: RoleValidatorModel = {
    status,
    valid,
    view,
    session,
  };

  return model;
};

export const SecuredView = ({
  loader,
  model,
}: {
  loader?: React.ReactNode;
  model: RoleValidatorModel;
}) => {
  const { status, view } = model;

  if (status === 'loading') {
    return loader ? loader : null;
  }

  return view;
};

export const useAdminPlusValidator = (opts?: RoleValidatorOpts) =>
  useRoleValidator(['ADMIN', 'OWNER'], 'GC', opts);
