import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Flex,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  LinkProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Text,
  VStack,
  useColorMode,
} from '@chakra-ui/react';
import {
  Breadcrumb as BreadcrumbType,
  LinkDataProps,
  SubNavLayoutConfig,
  useDebouncedState,
} from '@tb/common';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

const SubLinkComponent = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props: LinkProps, ref) => {
    const router = useRouter();
    const active = router.asPath === props.href;

    return (
      <Link variant={active ? 'nav_active' : 'nav'} ref={ref} {...props} />
    );
  },
);

SubLinkComponent.displayName = 'SubLinkComponent';

const SubNavLink = ({
  label,
  path,
  breadCrumb,
  noOverflow,
}: LinkDataProps & {
  breadCrumb?: boolean;
}) => {
  // const router = useRouter();

  let LinkComponent;
  if (!breadCrumb) {
    LinkComponent = SubLinkComponent;
  } else {
    LinkComponent = BreadcrumbLink;
  }
  if (path) {
    return (
      <NextLink data-testid={label} href={path} passHref legacyBehavior>
        <LinkComponent
          style={
            noOverflow
              ? {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }
              : undefined
          }
        >
          {label}
        </LinkComponent>
      </NextLink>
    );
  }

  return <LinkComponent>{label}</LinkComponent>;
};

export const SubNavLayoutV2 = ({
  heading,
  breadCrumbs,
  breadCrumbPlaceholder,
  linkPlaceholder,
  links,
  contextMenu,
}: SubNavLayoutConfig) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [allCrumbs, setAllCrumbs] = useState<BreadcrumbType[]>(
    breadCrumbPlaceholder || [],
  );
  const [crumbs, setCrumbs] = useState<BreadcrumbType[]>(
    breadCrumbPlaceholder || [],
  );
  const [renderedLinks, setRenderedLinks] = useState<LinkDataProps[]>(
    linkPlaceholder || [],
  );

  const { colorMode } = useColorMode();

  useEffect(() => {
    console.log('fetching crumbs');
    const fetchCrumbs = async () => {
      const result = await breadCrumbs();

      setCrumbs(result);

      // get options and store them for reuse
      let options;
      if (result[0] && 'options' in result[0]) {
        options = result[0].options;
        setAllCrumbs(options);
      }
    };

    fetchCrumbs();
  }, [breadCrumbs]);

  useEffect(() => {
    const fetchLinks = async () => {
      const result = await links();
      setRenderedLinks(result);
    };

    fetchLinks();
  }, [links]);

  const { debounceValue: searchInput, setDebounceValue: setSearchInput } =
    useDebouncedState<string>('', 1000, (inputValue) => {
      setShowSpinner(true);

      const filteredCrumbs = allCrumbs.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      );

      const crumbsCopy = [...crumbs];
      if ('options' in crumbsCopy[0] && filteredCrumbs) {
        crumbsCopy[0].options =
          filteredCrumbs.length > 0
            ? filteredCrumbs
            : [{ label: 'No Results Found' }];
      }

      setCrumbs(crumbsCopy);
      setShowSpinner(false);
    });

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <VStack
      w={'100%'}
      align={'stretch'}
      px={'60px'}
      bg={colorMode == 'light' ? 'white' : 'gray.700'}
      shadow={'base'}
      pt={4}
    >
      <Flex
        borderStyle="solid"
        borderColor="brand.hold"
        bg={colorMode == 'light' ? 'white' : 'gray.700'}
        alignItems={'center'}
        gap={1}
        overflowX="auto"
        justifyContent={'space-between'}
        w={'100%'}
      >
        {crumbs.length > 0 && (
          <Breadcrumb spacing={2} separator={'/'}>
            {renderedLinks.map((breadcrumb, idx) => {
              const { label, path, noOverflow } = breadcrumb;
              return (
                <BreadcrumbItem key={`${label}-${idx}`}>
                  <SubNavLink
                    path={path}
                    label={label}
                    breadCrumb
                    noOverflow={noOverflow}
                  />
                </BreadcrumbItem>
              );
            })}

            {crumbs.map((breadcrumb, idx) => {
              const { label } = breadcrumb;
              if ('options' in breadcrumb) {
                const { options, noOverflow } = breadcrumb;
                return (
                  <BreadcrumbItem key={`${label}-${idx}`}>
                    <Menu initialFocusRef={inputRef}>
                      <MenuButton as={BreadcrumbLink}>
                        <Flex gap={0}>
                          <Text
                            style={
                              !noOverflow
                                ? {
                                    maxWidth: '150px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }
                                : { whiteSpace: 'nowrap' }
                            }
                          >
                            {label}
                          </Text>
                          {breadcrumb.options.length > 0 && (
                            <ChevronDownIcon fontSize={'2xl'} />
                          )}
                        </Flex>
                      </MenuButton>
                      {breadcrumb.options.length > 0 && (
                        <Portal>
                          <MenuList
                            zIndex={1000}
                            overflowY={'auto'}
                            maxHeight={'70vh'}
                          >
                            <FormControl>
                              <InputGroup>
                                <Input
                                  onBlur={() => inputRef.current?.focus()}
                                  ref={inputRef}
                                  mx={2}
                                  w={'95%'}
                                  mb={2}
                                  value={searchInput}
                                  placeholder={'Search Projects...'}
                                  onChange={(e) =>
                                    setSearchInput(e.target.value)
                                  }
                                />
                                <InputRightElement>
                                  {!!showSpinner && <Spinner size="sm" />}
                                </InputRightElement>
                              </InputGroup>
                            </FormControl>
                            {options.map(({ label, path }, idx) => {
                              if (path) {
                                return (
                                  <NextLink
                                    key={`${label}-${idx}`}
                                    href={path}
                                    passHref
                                    legacyBehavior
                                  >
                                    <MenuItem as={'a'}>{label}</MenuItem>
                                  </NextLink>
                                );
                              }
                              return (
                                <MenuItem key={`${label}-${idx}`}>
                                  {label}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </Portal>
                      )}
                    </Menu>
                  </BreadcrumbItem>
                );
              }
              const { path } = breadcrumb;
              return (
                <BreadcrumbItem key={`${label}-${idx}`}>
                  <SubNavLink path={path} label={label} breadCrumb />
                </BreadcrumbItem>
              );
            })}
          </Breadcrumb>
        )}

        {/* <Flex alignItems={'center'} gap={8} fontSize="sm">
          {renderedLinks.map((breadcrumb, idx) => {
            const { label, path, noOverflow } = breadcrumb;
            return (
              <SubNavLink
                key={`${label}-${idx}`}
                path={path}
                label={label}
                noOverflow={noOverflow}
              ></SubNavLink>
            );
          })}
        </Flex> */}
        <Flex>
          {!!contextMenu && (
            <>
              <Menu preventOverflow={true}>
                <MenuButton
                  as={Button}
                  sx={{
                    color: 'complete.500',
                    bg: 'brand.white',
                    border: '1px solid',
                    borderColor: 'complete.500',
                    height: 7,
                  }}
                  rightIcon={<Icon fontSize="lg" as={ChevronDownIcon} />}
                >
                  Actions
                </MenuButton>
                <MenuList>
                  {contextMenu.map((item, idx) => {
                    return (
                      <MenuItem key={idx} onClick={item.onClick}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
              <Divider orientation="vertical" />
            </>
          )}
        </Flex>
      </Flex>
      {heading}
    </VStack>
  );
};
