import { Badge, Button } from '@chakra-ui/react';
import { CsiCode } from '@tb/common';

/* eslint-disable-next-line */
export interface CsiCodeRendererProps {
  csiCode: CsiCode;
  isButton?: boolean;
}

export const CsiCodeRenderer = ({
  csiCode,
  isButton = false,
}: CsiCodeRendererProps) => {
  const display = `${csiCode.code} - ${csiCode.description}`;
  if (isButton) {
    return (
      <Button size="sm" variant="outline">
        {display}
      </Button>
    );
  }
  return (
    <Badge
      borderRadius={2}
      p={1}
      display={'block'}
      whiteSpace={'pre'}
      maxW={['300px', '300px', '300px', '414px']}
      textOverflow={'ellipsis'}
      overflow={'hidden'}
      title={display}
    >
      {display}
    </Badge>
  );
};
