// Cached atom impl
// Can be used with atomWithStorage to control expired data
export type CachedAtom<T> = {
  data: T | undefined;
  expires: Date;
};
export const CacheAtomDefault = { data: undefined, expires: new Date(-1) };

export const expired = <T>(cachedAtom: CachedAtom<T>) => {
  return new Date(cachedAtom.expires) < new Date(Date.now());
};
