import {
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  useColorModeValue,
} from '@chakra-ui/react';
import { forwardRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { MdCalendarToday } from 'react-icons/md';
import { DatePickerWrapper } from './DatePickerWrapper';

interface DatePickerProps<FormValues extends FieldValues = FieldValues>
  extends UseControllerProps<FormValues> {
  label?: string;
  includeTime?: boolean;
  isRequired?: boolean;
  onChange?: (date: Date | null, e?: React.SyntheticEvent<any>) => void;
}

const CustomInput = forwardRef(
  ({ value, onClick, onChange, ...rest }: any, ref: any) => {
    const bgColor = useColorModeValue('white', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'white');

    return (
      <Input
        {...rest}
        autoComplete="off"
        backgroundColor={bgColor}
        color={textColor}
        value={value}
        ref={ref}
        onClick={onClick}
        onChange={onChange}
        placeholder="mm/dd/yyyy"
        borderRight={0}
        p={2}
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
      />
    );
  },
);

CustomInput.displayName = 'DateInput';

export const ControlledDatePicker = <
  FormValues extends FieldValues = FieldValues,
>({
  name,
  label,
  control,
  rules,
  shouldUnregister,
  includeTime,
  onChange,
}: DatePickerProps<FormValues>) => {
  const [isActive, setIsActive] = useState(false);
  const iconBg = useColorModeValue('#037632', 'gray.700');
  const iconColor = useColorModeValue('#fff', '#12B856');

  const {
    field,
    fieldState: { error },
  } = useController<FormValues>({
    name,
    control,
    rules,
    shouldUnregister,
  });

  let dateFormat = 'MM/dd/yyyy';

  if (includeTime) {
    dateFormat = 'MM/dd/yyyy h:mm aa';
  }

  return (
    <DatePickerWrapper id={name} isInvalid={!!error}>
      {!!label && <FormLabel>{label}</FormLabel>}
      <InputGroup
        zIndex={isActive ? 2 : undefined}
        onFocus={() => setIsActive(true)}
      >
        <ReactDatePicker
          onCalendarClose={() => setIsActive(false)}
          selected={field.value}
          onChange={(date, e) => {
            field.onChange(date, e);
            onChange?.(date, e);
          }}
          isClearable
          required={!!rules?.required}
          className="react-datapicker__input-text"
          dateFormat={dateFormat}
          customInput={<CustomInput />}
          showTimeSelect={includeTime}
          data-testid={name}
        />
        <InputRightAddon bg={iconBg}>
          <MdCalendarToday color={iconColor} />
        </InputRightAddon>
      </InputGroup>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </DatePickerWrapper>
  );
};
