import { Button, ButtonProps } from '@chakra-ui/react';
import { TbTableExport } from 'react-icons/tb';

export const ExportButton = (props: ButtonProps) => {
  return (
    <Button leftIcon={<TbTableExport />} variant="primary" {...props}>
      Export
    </Button>
  );
};
