import { Input, InputProps } from '@chakra-ui/react';
import React from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { DollarAmount } from '@tb/common';

export const MonetaryInput = ({
  value,
  currency = 'USD',
  ...inputProps
}: {
  value: DollarAmount;
  currency?: string;
} & Omit<Omit<CurrencyInputProps, 'size'> & InputProps, 'value'>) => {
  return (
    <Input
      textAlign={'right'}
      placeholder={'Amount'}
      as={CurrencyInput}
      decimalsLimit={2}
      intlConfig={{ locale: window.navigator.language, currency }}
      defaultValue={value?.amount ? value?.amount : undefined}
      {...inputProps}
    />
  );
};
