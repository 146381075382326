import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useMemo } from 'react';

type CollapseProps = {
  children: ReactNode;
  title: string;
  defaultOpen?: boolean;
  onCollapse?: () => void;
  disabled?: boolean;
  buttonProps?: ButtonProps;
  containerProps?: FlexProps;
  opened?: boolean;
};

export const Collapsible = ({
  children,
  title,
  defaultOpen,
  onCollapse,
  disabled,
  buttonProps,
  containerProps,
  opened,
}: CollapseProps) => {
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: defaultOpen,
    onClose: onCollapse,
    onOpen: onCollapse,
  });

  useEffect(() => {
    if (defaultOpen !== isOpen) {
      onToggle();
    }
  }, [defaultOpen]);

  const open = useMemo(
    () => (opened !== undefined ? opened : isOpen),
    [isOpen, opened],
  );

  return (
    <Flex flexDir={'column'} gap={4} {...containerProps}>
      <Button
        pointerEvents={disabled ? 'none' : 'auto'}
        onClick={disabled ? undefined : onToggle}
        justifyContent={'space-between'}
        rightIcon={
          disabled ? undefined : open ? (
            <ChevronUpIcon fontSize={20} />
          ) : (
            <ChevronDownIcon fontSize={20} />
          )
        }
        {...buttonProps}
      >
        {title}
      </Button>
      <Flex
        // visibility={isOpen ? 'initial' : 'hidden'}
        display={open ? 'initial' : 'none'}
        w={'-webkit-fill-available'}
        zIndex={100}
        // h={open ? '100%' : 0}
        // w={open ? '100%' : 0}
      >
        {children}
      </Flex>
    </Flex>
  );
};
