import { VStack, Divider, Flex, Text, StackProps } from '@chakra-ui/react';
import React from 'react';

export const AuditEntry = ({
  createdBy,
  createdAt,
  children,
  ...props
}: {
  createdBy?: string;
  createdAt: Date;
  children: React.ReactNode;
} & StackProps) => {
  return (
    <Stack createdBy={createdBy} createdAt={createdAt} {...props}>
      {children}
    </Stack>
  );
};

export const Stack = ({
  createdBy,
  createdAt,
  children,
  ...props
}: {
  createdBy?: string;
  createdAt: Date;
  children: React.ReactNode;
} & StackProps) => {
  return (
    <VStack
      overflow={'wrap'}
      flexWrap={'wrap'}
      alignItems={'flex-start'}
      {...props}
    >
      <Divider orientation="vertical" mr={5} />
      <CreatedBy username={createdBy} />
      {children}
      <CreatedAt date={createdAt} />
    </VStack>
  );
};

export const CreatedBy = ({ username }: { username?: string }) => {
  return (
    <Flex gap={2} justifyContent={'center'} alignItems={'center'}>
      <Text
        variant={'subtitle-muted'}
        borderBottom={'1px solid'}
        borderColor={'brand.darkGray'}
      >
        {username?.split('@')[0]}
      </Text>
    </Flex>
  );
};
export const CreatedAt = ({ date }: { date?: Date }) => {
  const formatted = date?.toLocaleDateString();

  return <Text variant={'body-sm-muted'}>{formatted}</Text>;
};
