import { useColorModeValue } from '@chakra-ui/react';

export const useColorModeStuff = () => {
  const BRIGHT_GREEN = 'green.100';
  const GREEN = 'green.500';
  const DARK_GREEN = 'green.300';

  const ICON_ACTIVE_COLOR_LIGHT = '#000';
  const ICON_ACTIVE_COLOR_DARK = '#000';

  const ICON_INACTIVE_COLOR_LIGHT = '#6B7280';
  const ICON_INACTIVE_COLOR_DARK = '#6B7280';

  //used
  const colorModeGreen = useColorModeValue(GREEN, BRIGHT_GREEN);
  const colorModeNormalText = useColorModeValue('theme.black', 'theme.white');
  const colorModeBigLogo = useColorModeValue(
    '/tb_logo.svg',
    '/tb_logo_dark.svg',
  );
  const colorModeSmallLogo = useColorModeValue(
    '/tb_logo_small.svg',
    '/tb_logo_small_dark.svg',
  );

  const colorModeSaturation5 = useColorModeValue('', 'saturate(5)');
  const colorModeSaturation2 = useColorModeValue('', 'saturate(2)');

  const colorModeActiveIcon = useColorModeValue(
    ICON_ACTIVE_COLOR_LIGHT,
    ICON_ACTIVE_COLOR_DARK,
  );
  const colorModeInactiveIcon = useColorModeValue(
    ICON_INACTIVE_COLOR_LIGHT,
    ICON_INACTIVE_COLOR_DARK,
  );

  //unused
  const colorModeGrayBg = useColorModeValue('#efefef', 'gray.800');
  const colorModeBorder = useColorModeValue('#000', BRIGHT_GREEN);
  const colorModeBlackAndWhite = useColorModeValue('#000', '#fff');

  const colorModeGreenHover = useColorModeValue(DARK_GREEN, GREEN);
  const colorModeBrightness = useColorModeValue('', 'brightness(2)');
  const colorModeInputBg = useColorModeValue('white', 'darkblue.700');

  const colorModeTabBottomBorder = useColorModeValue(GREEN, BRIGHT_GREEN);
  const colorModeBg = useColorModeValue('#efefef', 'gray.800');

  return {
    colorModeGrayBg,
    colorModeBorder,
    colorModeBlackAndWhite,
    colorModeGreen,
    colorModeNormalText,
    colorModeGreenHover,
    colorModeSaturation2,
    colorModeSaturation5,
    colorModeInputBg,
    colorModeBigLogo,
    colorModeSmallLogo,
    colorModeTabBottomBorder,
    colorModeBg,
    colorModeBrightness,
    colorModeActiveIcon,
    colorModeInactiveIcon,
  };
};
